import React, { useEffect, useRef, useState, forwardRef} from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { useNavigate, useParams } from 'react-router-dom';

import { Modal } from '@gravity-ui/uikit';

import MainPageView from 'components/MainPageView';
import {DownPageSteps} from './utils';
import DeviceTable from './DeviceTable';
import DeviceMainInfo from './MainInfo';
import DeviceQRPrinting from './QRPrinter';

import SelectDeviceType from './SelectType';
import DeviceServiceTable from './ServiceTable';
import DeviceAutoTestingTable from './AutoTesting';
import ManualDeviceTesting from './ManualTesting';
import DeviceReport from './Report';
import qCoreApi from 'api';
import {useToaster} from '@gravity-ui/uikit';


async function PreInstall(pin_number, device_id, onSuccess, onError) {
    try {
        const verifyResponse = await qCoreApi.verify(pin_number, 
            (data) => data, 
            (error) => { throw new Error(`Verify error: ${error}`); }
        );
        const runTestsResponse = await qCoreApi.run_autotests(device_id, 
            (data) => data, 
            (error) => { throw new Error(`Run Autotests error: ${error}`); }
        );
        onSuccess({ verifyResponse, runTestsResponse });
    } catch (error) {
        console.error(error);
        onError(error.message);
    }
}


const DeviceRegistrationPage = () => {
    console.log(process.env.REACT_APP_BASE_URL)
    const { t } = useTranslation("device_registration");

    const {add} = useToaster();

    const [step, setStep] = React.useState(3);
    const [manual_step, setManualStep] = React.useState(0);
    const [pin_number, setPinNumber] = React.useState('');

    const [device, setDevice] = React.useState({
        name : 'photon1',
        uuid : '123e4567-e89b-12d3-a456-426614174000',
        status : 'active'
    });

    const [selected_results, setSelectedResults] = useState({
        0:'fail',
        1:'fail',
        2:'fail',
        3:'fail'}
    )

    console.log(selected_results)

    const [devices, setDevices] = React.useState([]);
    
    const [tests, setTests] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [manualTests, setManualTests] = React.useState([
        {
            id: "Left Camera Test",
            name: t("device_test_left_camera_name"),
            test_id: "a7e94d12-ab65-4686-ba44-2971103b5d72",
            component_id: "49f3f25d-1b36-450e-8510-95ec1893c0e8",
        },
        {
            id: "Right Camera Test",
            name: t("device_test_right_camera_name"),
            test_id: "a7e94d12-ab65-4686-ba44-2971103b5d72",
            component_id: "9c9ebb5e-22b9-4c21-9577-8631f66e6cfe",
        },
        {
            id: "Cooling Test",
            name: t("device_cooling_test_name"),
            test_id: "b399b871-4fb6-42f7-b563-908d7c107e9f",
            component_id: "c17dec31-7137-4f67-a66a-2602d49dece6",
        },
        {
            id: "Display Test",
            name: t("device_display_test_name"),
            test_id: "b3546cff-6c6f-4cc7-a4d7-2ec098806256",
            component_id: "ede4c26f-a8ef-4853-9e93-ecb2d4f98f45",
        },
    ]);

    const sendManualTestResults = async () => {
        const testResults = Object.keys(selected_results).map((index) => {
            const test = manualTests[index];
            return {
                status: selected_results[index],
                value: 0,
                metadata: {},
                running_at: new Date().toISOString(),
                finished_at: new Date().toISOString(),
                test_id: test.test_id,
                device_id: device.uuid,
                component_id: test.component_id,
            };
        });
    
        await qCoreApi.send_manual_test_results(
            testResults,
            (data) => {
                console.log('Manual test results sent successfully', data);
            },
            (error) => {
                console.error('Error sending manual test results', error);
                add({
                    title: "Can't send manual test results",
                    isClosable: true,
                    theme: 'danger',
                    content: 'Please try again later',
                    autoHiding: 5000,
                    action: ['Retry'],
                });
            }
        );
    };

    const [device_types, setDeviceTypes] = React.useState([
        {
            value: 'Almaty 2024',
            content: 'Almaty 2024'
        }
    ]
    );

    const [selectedDeviceType, setSelected] = React.useState([]);
    const [showReport, setShowReport] = React.useState(false);

    const [install, setInstall] = React.useState(false);


    const navigate = useNavigate();
    const params = useParams();

    const onClose = () => {
        setStep(0);
        setManualStep(0);
        setShowReport(false);
        setSelected([]);
        setInstall(false);
    }


    useEffect(() => {
        qCoreApi.get_devices_registration_info((data) => {
            setDevices(data.devices.map((x) => {
                return {
                    uuid: x["id"],
                    ...x
                }
            }))
        }, (error) => {
            console.log(error);
            add({
                'title' : "Can't get devices",
                'isClosable' : true,
                
            })

        })
    }, []);


    useEffect(() => {
        if (device.uuid) {
            qCoreApi.get_tests_result(
                device.uuid,
                (data) => {
                    setTests(data || []);
                },
                (error) => {
                    console.log(error);
                    add({
                        title: "Can't get tests",
                        isClosable: true,
                        theme: 'danger',
                        content: 'Failed to fetch tests. Please try again later.',
                    });
                }
            );
        }
    }, [device.uuid]);

    if (step === 0) {
        return <DeviceTable
            devices={devices}
            onNextStep={async (pin_number) => {
                console.log(pin_number)
                await qCoreApi.register_device(pin_number, (data) => {
                    setDevice(data.device);
                    console.log(data.device)
                    setStep(1);
                    setPinNumber(pin_number);
                }
                , (error) => {
                    console.log(error);
                    add({
                        'title' : "Can't register device",
                        'isClosable' : true,
                        'theme' : 'danger',
                        'content' : 'Please try again later',
                        'autoHiding': 5000,
                        'action' : ['Retry']
                    })
                })
                
            }}
        />
    } else if (step === 1) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <DeviceQRPrinting
                name={device.name}
                uuid={device.uuid}
            />
            <DownPageSteps
                hasPrevious={false}
                rightContent={t("next_step")}
                onNext={() => setStep(2)}
            />
        </MainPageView>
    } else if (step === 2) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <SelectDeviceType 
                onSelect={setSelected}
                onInstall={async () => {
                    await PreInstall(
                        pin_number,
                        device.uuid, 
                        (data) => {
                            console.log(data);
                            setStep(3);
                        },
                        (error) => {
                            console.log(error);
                            add({
                                'title' : "Can't process the device",
                                'isClosable' : true,
                                'theme' : 'danger',
                                'content' : 'Please try again later',
                                'autoHiding': 5000,
                                'action' : ['Retry']
                            });
                        }
                    );
                }}
                selected={selectedDeviceType}
                options={device_types}
            />
            {
                install ? < DeviceServiceTable services={services}/> : null
            }
             
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => setStep(1)}
                rightContent={t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => setStep(3)}
            />
        </MainPageView>
    } else if (step  === 3) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <DeviceAutoTestingTable
                tests = {tests}
            />
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => setStep(2)}
                rightContent={t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => {setStep(4); setManualStep(0)}}
            />
        </MainPageView>
    } else if (step === 4) {
        return <MainPageView>
            <DeviceMainInfo
                name={device.name}
                uuid={device.uuid}
                status={device.status}
                step={step-1}
                onClose={onClose}
            />
            <ManualDeviceTesting 
                selected_results={selected_results}
                setSelectedResults={setSelectedResults}
                step={manual_step}
                device={device}
                manualTests={manualTests}
            />
            <DownPageSteps
                hasPrevious={true}
                onPrevious={() => {
                    if (manual_step > 0) {
                        setManualStep(manual_step-1)
                    } else {
                        setStep(3)
                        setManualStep(0)
                    }
                }}
                rightContent={manual_step===3?t("finish_step"):t("next_step")}
                leftContent={t("previous_step")}
                onNext={() => {
                    if (manual_step < 3) {
                        setManualStep(manual_step + 1);
                    } else {
                        sendManualTestResults();
                        setShowReport(true);
                    }
                }}
            />
          <Modal
            open={showReport}
            onClose={() => setShowReport(false)}
          >
            <DeviceReport
                device_name={device.name}
                uuid={device.uuid}
                registraion_date={"2024-09-01T00:00:00"}
                installation_date={"2024-09-01T00:00:00"}
                autotests={tests}
                manual_tests={tests}
                onClose={onClose}
            />
          </Modal>
        </MainPageView>
    } 
};


export default DeviceRegistrationPage;