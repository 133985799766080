import React, { useState, useRef } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Card, Button, RadioGroup, RadioButton, Slider } from '@gravity-ui/uikit';
import { Stepper, Step, StepLabel } from '@mui/material';




const ManualDeviceTest = ({test, device, selected_results, setSelectedResults}) => {
    const { t } = useTranslation("device_registration");
   
    const left_camera_options = [
        {
            value: 'success',
            content: t("device_camera_test_success")
        },
        {
            value: 'fail',
            content: t("device_camera_test_fail")
        }]

    const cooling_test_options = [
        {
            value: 'success',
            content: t("device_cooling_test_success")
        },
        {
            value: 'fail',
            content: t("device_cooling_test_fail")
        }]
        
    const display_test_options = [
        {
            value: 'success',
            content: t("device_display_test_success")
        },
        {
            value: 'fail',
            content: t("device_display_test_fail")
        }]

    // console.log(selected_results);
    // console.log(device);
    // console.log(test)

    if (test.id === "Left Camera Test") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'baseline',
                    alignItems: 'baseline',
                }}
            >
                <h3>{test.name}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                        
                        
                    }}
                >
                    <img src="https://via.placeholder.com/150" height={500} />
                    <RadioGroup 
                        name="group1"
                        defaultValue='' 
                        options={left_camera_options} 
                        direction="vertical"
                        value = {selected_results[0]}
                        onUpdate={(value) => { setSelectedResults({ ... selected_results, 0: value}) }}
                    />
                </div>
            </div>
        )
    } else if (test.id === "Right Camera Test") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'baseline',
                    alignItems: 'baseline',
                }}
            >
                <h3>{test.name}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                    }}
                >
                    <img src="https://via.placeholder.com/150" height={500} />
                    <RadioGroup 
                        name="group2" 
                        defaultValue='' 
                        options={left_camera_options} 
                        direction="vertical"
                        value = {selected_results[1]}
                        onUpdate={(value) => { setSelectedResults({ ... selected_results, 1: value}) }}
                    />
                </div>
            </div>
        )
    } else if (test.id === "Cooling Test") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'baseline',
                    alignItems: 'baseline',
                }}
            >
                <h3>{test.name}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                        alignItems: 'baseline',
                    }}
                >
                    <Card
                        size='l'
                        style={{
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            alignItems: 'baseline',
                            gap: '20px',
                        }}
                    >
                        <Button
                            size='xl'
                        >
                            {t("device_cooling_test_start_button")}
                        </Button>
                        <RadioButton
                        size='xl'
                            options={
                                [
                                    {
                                        value: "min",
                                        content: t("device_cooling_test_min")
                                    },
                                    {
                                        value: "avg",
                                        content: t("device_cooling_test_avg")
                                    },
                                    {
                                        value: "max",
                                        content: t("device_cooling_test_max")
                                    }
                                ]
                            }
                            />
                    </Card>
                    <RadioGroup 
                        name="group2" 
                        defaultValue='' 
                        options={cooling_test_options} 
                        direction="vertical"
                        value = {selected_results[2]}
                        onUpdate={(value) => { setSelectedResults({ ... selected_results, 2: value}) }}
                    />
                </div>
            </div>
        )
    } else if (test.id === "Display Test") {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                    justifyContent: 'baseline',
                    alignItems: 'baseline',
                }}
            >
                <h3>{test.name}</h3>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                        
                        
                    }}
                >
                    <div
                        style={{
                            maxWidth: '500px',
                        }}
                    >
                    <Slider 
                        size='xl'
                    />
                    </div>
                    <RadioGroup 
                        name="group2" 
                        defaultValue='' 
                        options={display_test_options} 
                        direction="vertical"
                        value = {selected_results[3]}
                        onUpdate={(value) => { setSelectedResults({ ... selected_results, 3: value}) }}
                    />
                </div>
            </div>
        )
    }
}


const ManualDeviceTesting = ({onNextStep, step, device, selected_results, setSelectedResults, manualTests}) => {
    const { t } = useTranslation("device_registration");
    const tests = manualTests;



    return <div
            style ={{
                display: 'flex',
                flexDirection: 'row',
                gap: '40px',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'baseline',
                marginTop: '0px'
            }}

        >
        <Card

            size='l'
            style={{
                flex:1,
                
                minWidth: '300px',
                paddingTop: '25px',
                paddingBottom: '25px',
                paddingLeft: '25px',
                paddingRight: '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'baseline',
                gap: '10px'
            }}
        >
            <Stepper
                orientation="vertical"
                activeStep={step}
                
            >
                {tests.map((test, index) => (
                    <Step key={test.name}>
                        
                        <StepLabel icon={''}>{test.name}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Card>  
        <Card
            style = {{
                flex: 5,
                paddingTop: '25px',
                paddingBottom: '25px',
                paddingLeft: '25px',
                paddingRight: '25px',
            }}
        >
            <ManualDeviceTest
                test={manualTests[step]}
                device={device}
                selected_results={selected_results}
                setSelectedResults={setSelectedResults}
            />
        </Card>

    </div>
}


export default ManualDeviceTesting;